import { ChoiceField, LineField, Option } from "@/classes";
import { description, label, name, placeholder, url, value } from "../common/fields";


const emailField = {
    id: 'email',
    name: "Email",
    data: {
        static: {
            label: "Email",
            placeholder: "Enter Email Id",
            required: false,
            description: "",
            value: "",
            verification: false
        }
    },
    fields: [
        label(),
        placeholder(),
        description(),
        value()
    ]
}

const fullNameField = {
    id: 'fullName',
    data: {
        static: {
            firstNameLabel: "First Name",
            firstNamePlaceholder: "Enter First Name",
            firstNameValue: "",
            firstNameRequired: false,
            middleNameLabel: "Middle Name",
            middleNamePlaceholder: "Enter Middle Name",
            middleNameValue: "",
            middleNameRequired: false,
            middleNameShow: false,
            lastNameLabel: "Last Name",
            lastNamePlaceholder: "Enter Last Name",
            lastNameValue: "",
            lastNameRequired: false,
        }
    },
    fields: [
        name("First", "Label"),
        name("First", "Placeholder"),
        name("First", "Value"),
        new LineField().data,
        name("Middle", "Label"),
        name("Middle", "Placeholder"),
        name("Middle", "Value"),
        new LineField().data,
        name("Last", "Label"),
        name("Last", "Placeholder"),
        name("Last", "Value")
    ]
}

const addressField = {
    id: "address",
    name: "Address"
}

const mobileNoField = {
    id: "mobileNo",
    name: "Mobile No",
    data: {
        static: {
            label: "Mobile No",
            placeholder: "Enter Mobile No",
            required: false,
            description: "",
            value: "",
            // countries: ['all', 'some', 'one'],
            countries: [
                {
                    id: 'india',
                    name: "India"
                }
            ]
        }
    },
    fields: [
        label(),
        placeholder(),
        description(),
        value(),
        new ChoiceField("singleChoice", "Select Countries", "normal", "countries", [
            new Option("small", "Small").data,
            new Option("normal", "Normal").data
        ]).data
    ]
}

const termsAndConditionsField = {
    id: 'termsAndConditions',
    name: "Terms And Conditions",
    data: {
        static: {
            url: ""
        }
    },
    fields: [url()]
}

export {
    emailField,
    fullNameField,
    mobileNoField,
    addressField,
    termsAndConditionsField
}