import { formsSubscriber, getFormsCount } from '@/firebase/firestore/forms'
import { createEvent, EVENTS } from '@/firebase/firestore/users'

import builder from './builder';
import router from '@/router';

export default {
    namespaced: true,
    state: () => {
        return {
            form: {
                navigation: 'builder',
                preview: false
            },
            forms: [],
            formsSubscriber: null
        }
    },
    getters: {
        formCount(state) {
            return state.forms.length
        }
    },
    mutations: {
        RESET(state) {
            if (state.formsSubscriber !== null) {
                state.formsSubscriber()
                state.formsSubscriber = null
            }
            state.form = {}
            state.forms = []
        },
        SET_NAVIGATION(state, name) {
            if (name === undefined) return
            if (name === 'preview') state.form.preview = true
            else state.form.preview = false
            state.form.navigation = name
            if (router.currentRoute.params.id != null) createEvent(EVENTS.PAGES.ACCOUNT.FORMS[name.toUpperCase()], { id: router.currentRoute.params.id })
        },
        SET_SUBSCRIBER(state, subscriber) {
            state.formsSubscriber = subscriber
        },
        ADD_FORM(state, form) {
            state.forms.push(form)
        },
        DELETE_FORM(state, formId) {
            state.forms = state.forms.filter(form => form.id !== formId)
        },
        UPDATE_FORM(state, form) {
            const index = state.forms.map(function(x) {return x.id; }).indexOf(form.id)
            state.forms.splice(index, 1, form)
        },
        CHECK_COUNT(state) {
            if (state.forms.length == 0) {
                this.commit('core/SET_LOADING', true)
                getFormsCount().then(() => {
                    this.commit('core/SET_LOADING', false)
                })
            }
        }
    },
    actions: {
        GET_FORMS({ state }) {
            if (state.formsSubscriber == null) formsSubscriber()
        }
    },
    modules: {
        builder
    }
}