import { imageField, textField, titleField, videoField } from "./display";
import { fileUploadField, fingerprintField, imageUploadField, qrOrBarCodeScanField } from "./custom";
import { addressField, emailField, fullNameField, mobileNoField, termsAndConditionsField } from "./preconfigured";
import { dropdownField, dateAndTimeField, dateField, multiChoiceField,  signatureField, singleChoiceField, sliderField, switchField, textInputField, textInputMultiLineField, timeField } from "./input";


export default [
    {
        id: 1,
        type: "display",
        title: "Display Fields",
        fields: [
            { id: 'text', name: "Text", field: textField },
            { id: 'title', name: "Title", field: titleField },
            { id: 'image', name: "Image", field: imageField },
            // { id: 'editor', name: "Editor", field: editorField },
            // { id: 'carousal', name: "Carousal", field: carousalField },
            { id: 'video', name: "Video", field: videoField }
        ]
    },
    {
        id: 2,
        type: "input",
        title: "PreConfigured Fields",
        fields: [
            { id: 'email', name: "Email", field: emailField },
            { id: 'number', name: "Number", field: null },
            { id: 'mobileNo', name: "Mobile No", field: mobileNoField },
            { id: 'fullName', name: "Full Name", field: fullNameField },
            { id: 'address', name: "Address", field: addressField },
            { id: 'termsAndConditions', name: "Terms And Conditions", field: termsAndConditionsField }
        ]
    },
    {
        id: 3,
        type: "input",
        title: "Input Fields",
        fields: [
            { id: 'textInput', name: "Text Input", field: textInputField },
            { id: 'textInputMultiLine', name: "Text Input (Multi Line)", field: textInputMultiLineField },
            { id: 'singleChoice', name: "Single choice", field: singleChoiceField },
            { id: 'multiChoice', name: "Multi Choice", field: multiChoiceField },
            { id: 'dropdown', name: "Dropdown", field: dropdownField },
            { id: 'switch', name: "Switch", field: switchField },
            { id: 'slider', name: "Slider", field: sliderField },
            { id: 'date', name: "Date", field: dateField },
            { id: 'time', name: "Time", field: timeField },
            { id: 'dateAndTime', name: "Date And Time", field: dateAndTimeField },
            // { id: 'ratings', name: "Ratings", field: ratingsField },
            { id: 'signature', name: "Signature", field: signatureField },
            // { id: 'multipleChoiceTable', name: "Multiple Choice Table", field: multipleChoiceTableField }
        ]
    },
    {
        id: 4,
        type: "input",
        title: "Custom Fields",
        fields: [
            { id: 'fileUpload', name: "File Upload", field: fileUploadField },
            { id: 'imageUpload', name: "Image Upload", field: imageUploadField },
            { id: 'qrOrBarCodeScan', name: "Qr/Bar Code Scan", field: qrOrBarCodeScanField },
            { id: 'fingerprint', name: "Fingerprint", field: fingerprintField }
        ]
    }
]