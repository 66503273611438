<template>
  <div id="app">
    <Navbar v-if="$route.path !== '/'" />
    <router-view />
    <Footer />
    <b-loading :active="$store.state.core.loading && !$store.state.core.authLoaded"></b-loading>
  </div>
</template>

<script>
import Navbar from '@/components/navbar'
import Footer from '@/components/footer';

import { addEvent } from './firebase/analysis';

export default {
  name: 'Root',
  components: {
    Navbar,
    Footer
  },
  mounted() {
    addEvent("page_loaded")
  }
}
</script>

<style>
.modal-background {
  background-color: rgba(10, 10, 10, 0.26);
}
</style>