import { Route } from "@/classes";


export default [
    new Route('home', '/', 'main/home', false).route,
    new Route('features', '/features', 'main/features', false).route,
    new Route('templates', '/templates', 'main/templates', false).route,
    new Route('pricings', '/pricings', 'main/pricings', false).route,
    new Route('integrations', '/integrations', 'main/integrations', false).route,
    new Route('support', '/support', 'main/support', false).route,
    
    new Route('terms-and-conditions', '/policies/terms-and-conditions', 'components/common/terms-and-conditions', false).compRoute,
    new Route('privacy-policy', '/policies/privacy-policy', 'components/common/privacy-policy', false).compRoute
]