import { ChoiceField, MultiField, Option } from "@/classes";
import { imageName, imageUrl, text, videoUrl } from "../common/fields";


const carousalField = {
    id: "carousal",
    name: "Carousal",
    data: {
        static: {
            urls: []
        }
    },
    fields: [
        new MultiField("multi", "Add Urls", "urls", [ imageName(), imageUrl() ]).data    
    ]
}

const editorField = {
    id: "editor",
    name: "Editor"
}

const imageField = {
    id: "image",
    name: "Image",
    data: {
        static: {
            url: "https://buefy.org/static/img/buefy.1d65c18.png",
            name: "Logo"
        }
    },
    fields: [
        imageUrl(),
        imageName()
    ]
}

const textField = {
    id: "text",
    name: "Text",
    data: {
        static: {
            text: "Text"
        }
    },
    fields: [
        text()
    ]
}

const titleField = {
    id: "title",
    name: "Title",
    data: {
        static: {
            title: "Title",
            subTitle: "Sub Title",
            subTitleRequired: true,
            type: "normal"
        }
    },
    fields: [
        {
            type: "textInputMultiLine",
            label: "Title",
            placeholder: "Enter title",
            value: "Title",
            elm: "title"
        },
        {
            type: "textInputMultiLine",
            label: "Sub Title (Help Text)",
            placeholder: "Enter Sub Title",
            value: "Sub Title",
            elm: "subTitle"
        },
        new ChoiceField("singleChoice", "Title Style", "normal", "type", [
            new Option("small", "Small").data,
            new Option("normal", "Normal").data,
            new Option("large", "Large").data,
            new Option("extralarge", "Extra Large").data
        ]).data,
        {
            type: 'switch',
            label: "Sub Title",
            elm: "subTitleRequired"
        }
    ]
}

const videoField = {
    id: "video",
    name: "Video",
    data: {
        static: {
            type: "url",
            url: "https://vjs.zencdn.net/v/oceans.mp4"
        }
    },
    fields: [
        videoUrl(),
        new ChoiceField("singleChoice", "Video Type", "url", "type", [
            new Option("url", "Video Link").data,
            new Option("youtube", "Youtube").data
        ]).data
    ]
}

export {
    carousalField,
    editorField,
    imageField,
    textField,
    titleField,
    videoField
}