export default {
    namespaced: true,
    state: () => {
        return {
            authLoaded: false,
            loading: false
        }
    },
    mutations: {
        SET_LOADING(state, loaded) {
            state.loading = loaded
        },
        SET_AUTH_LOADED(state) {
            state.authLoaded = true
        }
    }
}