import { MultiField } from "@/classes";
import { imageName, imageUrl } from "../common/fields";

const fileUploadField = {
    id: "fileUpload",
    name: "File Upload",
    data: {
        static: {
            urls: []
        }
    },
    fields: [
        new MultiField("multi", "Add Urls", "urls", [ imageName(), imageUrl() ]).data    
    ]
}

const imageUploadField = {
    id: "imageUpload",
    name: "Image Upload"
}

const qrOrBarCodeScanField = {
    id: "qrOrBarCodeScan",
    name: "Qr / BarCode Scan"
}

const fingerprintField = {
    id: "fingerprint",
    name: "Fingerprint"
}

export {
    fileUploadField,
    imageUploadField,
    qrOrBarCodeScanField,
    fingerprintField
}