import { setDoc, addDoc } from "firebase/firestore";
import { userRef, userEventCollection, userMessagingCollection } from "..";
import { addEvent, addEventPayload } from "@/firebase/analysis";

import store from '@/store'
import EVENTS from '@/static/events'

const createUser = (userId, name, email) => {
    setDoc(userRef(userId), {name, email})
}

const createEvent = (name, object) => {
    if ('FIREBASE' in name) {
        (object == null) ? addEvent(name.FIREBASE) : addEventPayload(name.FIREBASE, object);
    }
    if (store.state.user.id == null) return;
    object = object == null ? {} : object;
    addDoc(userEventCollection(store.state.user.id), {
        name: name.SITE,
        data: object,
        ts: new Date(),
        userAgent: navigator.userAgent
    })
}

const createEventTime = (name, object) => {
    if ('FIREBASE' in name) {
        (object == null) ? addEvent(name.FIREBASE) : addEventPayload(name.FIREBASE, object);
    }
    if (store.state.user.id == null) return;
    object = object == null ? {} : object;
    let data = {
        name: name.SITE,
        data: object,
        ts: new Date(),
        userAgent: navigator.userAgent
    }
    const complete = () => {
        data.time = new Date() - data.ts;
        addDoc(userEventCollection(store.state.user.id), data); 
    }
    return complete
}

const createToken = (token) => {
    addDoc(userMessagingCollection(store.state.user.id), { token, ts: new Date(), userAgent: navigator.userAgent })
}

export { createUser, createEvent, createEventTime, createToken, EVENTS }