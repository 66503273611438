import { EditField } from "@/classes";


const url = () => new EditField("textInput", "Url", "Enter Url", "url").data;

const imageUrl = () => new EditField("textInput", "Url", "Enter Image Url", "url").data;
const imageName = () => new EditField("textInput", "Name", "Enter Image Name", "name").data;

const videoUrl = () => new EditField("textInput", "Url", "Enter Video Url", "url").data;

const label = () => new EditField("textInput", "Label", "Enter Label", "label").data;
const text = () => new EditField("textInputMultiLine", "text", "Enter text", "text").data
const value = () => new EditField("textInput", "Value", "Enter default value", "value").data;
const placeholder = () => new EditField("textInput", "Placeholder", "Enter placeholder", "placeholder").data;
const description = () => new EditField("textInputMultiLine", "Description", "Enter Description", "description").data;

const multiId = (type) => new EditField("textInput", `${type} Id`, `Enter ${type} Id`, "id").data;
const multiLabel = (type) => new EditField("textInput", `${type} Label`, `Enter ${type} Label`, "label").data;

const minimumDate = () => new EditField("dateAndTime", "Minimum Date", "Select Minimum Date", "minimumDate").data;
const maximumDate = () => new EditField("dateAndTime", "Maximum Date", "Select Maximum Date", "maximumDate").data;

const minimumTime = () => new EditField("time", "Minimum Time", "Select Minimum Time", "minimumTime").data;
const maximumTime = () => new EditField("time", "Maximum Time", "Select Maximum Time", "minimumTime").data;

const name = (type, field) => new EditField("textInput", `${type} Name ${field}`, `Enter ${type} Name ${field}`, `${type.toLowerCase()}Name${field}`).data;

export { description, imageName, imageUrl, label, placeholder, value, multiId, multiLabel, minimumDate, maximumDate, minimumTime, maximumTime, url, name, text, videoUrl }
