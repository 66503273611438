import Vue from "vue";
import VueRouter from "vue-router";

import store from  '@/store'

import accountRoutes from "./account"
import formRoutes from "./form"
import homeRoutes from "./home"

Vue.use(VueRouter);

const routes = [
  ...formRoutes,
  ...homeRoutes,
  ...accountRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (store.state.user.id !== null || !store.state.core.authLoaded) next();
    else next({ path: '/' });
  } else next();
});

export default router;
