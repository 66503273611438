<template>
    <form action="">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Login</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field label="Email" label-position="inside">
                            <b-input type="email" v-model="form.email" placeholder="Enter your Email ID" required/>
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field label="Password" label-position="inside">
                            <b-input type="password" v-model="form.password" password-reveal placeholder="Your password" required/>
                        </b-field>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="columns is-vcentered" style="width: 100%;">
                    <div class="column is-6" v-if="boolean.rememberMe">
                        <b-checkbox v-model="form.checked">Remember Me</b-checkbox>
                    </div>
                    <div :class="['column', 'is-6', !boolean.rememberMe ? 'is-offset-6' : '']">
                        <b-button label="Login" expanded @click="login()" type="is-primary" :disabled="form.email.length < 4 || form.password.length < 4"/>
                    </div>
                </div>
            </footer>
        </div>
    </form>
</template>

<script>
import { loginUser } from '@/firebase/auth'

export default {
  name: "LoginForm",
  data() {
    return {
        boolean: {
            rememberMe: false
        },
        form: {
            email: "",
            password: "",
            checked: false
        }
    };
  },
  methods: {
    async login () {
      this.$store.commit('core/SET_LOADING', true)
      try {
        await loginUser(this.form.email, this.form.password);
        this.$emit('close')
        this.$router.push({ name: 'dashboard' })
      } catch(e) {
        console.log(e.code)
      }
      this.$store.commit('core/SET_LOADING', false)
    },
  },
};
</script>