import { addDoc, updateDoc, getDoc, getDocs, onSnapshot, setDoc } from "firebase/firestore";
import { formRef, getFormsById, formsCollection, formPageRef } from "..";
import { createEvent, createEventTime, EVENTS } from "@/firebase/firestore/users";
import { generateId } from "@/utils";

import store from '@/store'
import router from '@/router'

const createForm = async () => {
    store.commit('core/SET_LOADING', true)
    let callback = createEventTime(EVENTS.EVENTS.CREATE_FORM, null);
    const doc = await addDoc(formsCollection(), {
        name: `Form ${generateId(10)}`,
        entries: 0,
        views: 0,
        visibility: true,
        status: 'unpublished',
        dateCreated: new Date(),
        userId: store.state.user.id
    });
    callback();
    callback = createEventTime(EVENTS.EVENTS.CREATE_FORM_PAGES, { id: doc.id });
    await setDoc(formPageRef(doc.id, 'index'), {
        pages: [
            {
                id: generateId(30),
                fields: [],
                footerFields: []
            },
            {
                id: generateId(30),
                fields: [],
                footerFields: []
            }
        ],
        userId: store.state.user.id,
        dateCreated: new Date()
    })
    callback();
    router.push({ name: 'builder', params: { id: doc.id } })
    store.commit('core/SET_LOADING', false)
}

const getFormPageByFormId = async (formId) => {
    store.commit('core/SET_LOADING', true)
    const callback = createEventTime(EVENTS.EVENTS.GET_FORM_PAGES, { id: formId, type: 'index' })
    const doc = await getDoc(formPageRef(formId, 'index'))
    callback();
    store.commit('forms/builder/SET_PAGES', doc.data()['pages'])
}

const getFormPublicPageByFormId = async (formId) => {
    store.commit('core/SET_LOADING', true)
    const callback = createEventTime(EVENTS.EVENTS.GET_FORM_PAGES, { id: formId, type: 'public' })
    const doc = await getDoc(formPageRef(formId, 'public'))
    callback();
    store.commit('forms/builder/SET_PAGES', doc.data()['pages'])
}

const setFormPageData = async (formId, pages, type) => {
    const callback = createEventTime(EVENTS.EVENTS.UPDATE_FORM_PAGES, { id: formId, type });
    await setDoc(formPageRef(formId, type), { pages, userId: store.state.user.id, dateCreated: new Date() })
    callback();
}

const formsSubscriber = () => {
    const subscriber = onSnapshot(getFormsById(store.state.user.id), querySnapshot => {
        querySnapshot.docChanges().forEach((change) => {
            if (change.type === "removed") store.commit('forms/DELETE_FORM', change.doc.id);
            if (change.type === "added") store.commit('forms/ADD_FORM', { id: change.doc.id, data: change.doc.data() });
            if (change.type === "modified") store.commit('forms/UPDATE_FORM', { id: change.doc.id, data: change.doc.data() });
        });
    });
    store.commit('forms/SET_SUBSCRIBER', subscriber)
}

const getFormsCount = async () => {
    const callback = createEventTime(EVENTS.EVENTS.FORM_COUNT, null);
    const querySnapshot = await getDocs(getFormsById(store.state.user.id));
    callback();
    return querySnapshot.size
}

const getFormById = async (formId) => {
    const callback = createEventTime(EVENTS.EVENTS.GET_FORM, { id: formId });
    const docSnap = await getDoc(formRef(formId));
    callback();
    if (docSnap.exists) return docSnap.data()
}

const changeStatus = (formId, status) => {
    createEvent(EVENTS.EVENTS.FORM_STATUS_CHANGE, { id: formId, status });
    updateDoc(formRef(formId), { status })
}

const deleteForm = (formId) => {
    createEvent(EVENTS.EVENTS.DELETE_FORM, { id: formId });
    updateDoc(formRef(formId), { visibility: false })
}

export {
    createForm,
    formsSubscriber,
    getFormById,
    getFormsCount,
    changeStatus,
    deleteForm,
    getFormPageByFormId,
    setFormPageData,
    getFormPublicPageByFormId
}