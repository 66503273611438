import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
import { getPerformance } from "firebase/performance";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const app = initializeApp({
    apiKey: "AIzaSyBKGerV8MxTsJpQUfx9kVl2JnmH9gV18VU",
    authDomain: "engineer-form-qaa.firebaseapp.com",
    projectId: "engineer-form-qaa",
    storageBucket: "engineer-form-qaa.appspot.com",
    messagingSenderId: "187050810522",
    appId: "1:187050810522:web:74b08a9867963645831323",
    measurementId: "G-TCLD9Q45KR",
    databaseURL: "https://engineer-form-qaa-default-rtdb.europe-west1.firebasedatabase.app",
});

// const appCheck = 
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LdJSmcfAAAAAEl1Ps7cOSwak5XYRU8KHVVDeKMt'),
    isTokenAutoRefreshEnabled: true
});

const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const realtimeDb = getDatabase(app);
const messaging = getMessaging(app);
const firestoreDb = getFirestore(app);
const performance = getPerformance(app);

export { app, auth, storage, realtimeDb, firestoreDb, analytics, performance, messaging }