import Vue from 'vue';
import App from './App.vue';

import '@/firebase';

// import './registerServiceWorker's

import store from './store';
import router from './router';

Vue.config.productionTip = false

import "croppr/dist/croppr.min.css";
import "buefy/dist/buefy.min.css";

import Buefy from "buefy";
Vue.use(Buefy);

import SuiVue from 'semantic-ui-vue';
Vue.use(SuiVue);

import VueVideoPlayer from 'vue-video-player'
Vue.use(VueVideoPlayer);

import VueSignaturePad from 'vue-signature-pad'
Vue.use(VueSignaturePad);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
