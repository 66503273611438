import { collection, doc, where, query } from "firebase/firestore";
import { firestoreDb } from "../init";

const getRef = (col, id) => doc(firestoreDb, col, id)
const getCol = (col) => collection(firestoreDb, col)

const userRef = (userId) => getRef('users', userId)
const formRef = (formId) => getRef('forms', formId)
const templateRef = (templateId) => getRef('templates', templateId)
const collectionRef = (collectionId) => getRef('collections', collectionId)

const formPageRef = (formId, type) => doc(firestoreDb, 'forms', formId, 'form_pages', type)

const formsCollection = () => getCol('forms')
const templatesCollection = () => getCol('templates')
const collectionsCollection = () => getCol('collections')

const userEventCollection = (userId) => collection(firestoreDb, 'users', userId, 'user_events')
const userMessagingCollection = (userId) => collection(firestoreDb, 'users', userId, 'user_messaging_tokens')

const getFormsById = (userId) => query(formsCollection(), where("userId", "==", userId), where('visibility', '==', true));

export {
    userRef,
    formRef,
    templateRef,
    collectionRef,

    formPageRef,

    formsCollection,
    templatesCollection,
    collectionsCollection,

    userEventCollection,
    userMessagingCollection,

    getFormsById
}