class Field {

    constructor(type, name) {
        this.type = type;
        this.name = name;
    }
}

class FieldType {

    constructor(type, title, fields) {
        this.type = type;
        this.title = title;
        this.fields = fields
    }
}

class MultiField {
    
    constructor(type, label, element, fields) {
        this.type = type;
        this.label = label;
        this.fields = fields;
        this.element = element;
    }

    get data() {
        return {
            type: this.type,
            label: this.label,
            elm: this.element,
            fields: this.fields
        }
    }
}

class EditField {

    constructor(type, label, placeholder, element) {
        this.type = type;
        this.label = label;
        this.element = element;
        this.placeholder = placeholder;
    }

    get data() {
        return {
            type: this.type,
            label: this.label,
            elm: this.element,
            placeholder: this.placeholder
        }
    }
}

class LineField {

    get data() {
        return {
            type: 'line'
        }
    }
}

class ChoiceField {

    constructor(type, label, value, element, options) {
        this.type = type;
        this.label = label;
        this.value = value;
        this.options = options;
        this.element = element;
    }

    get data() {
        return {
            type: this.type,
            elm: this.element,
            label: this.label,
            value: this.value,
            options: this.options
        }
    }
}

class Option {
    
    constructor(id, label) {
        this.id = id;
        this.label = label;
    }

    get data() {
        return {
            id: this.id,
            label: this.label
        }
    }
}

class DraggableField {

    constructor(id, name, staticData, fields) {
        this.id = id;
        this.name = name;
        this.data = {
            static: staticData
        };
        this.fields = fields
    }

    get value() {
        return {
            id: this.id,
            data: this.data,
            name: this.name,
            fields: this.fields
        }
    }
}

class Route {

    constructor(name, path, component, authRequired) {
        this.name = name;
        this.path = path;
        this.meta = { authRequired };
        this.component = component;
    }

    get route () {
        return {
            name: this.name,
            path: this.path,
            meta: this.meta,
            component: () => import(/* webpackMode: "lazy" */ `@/pages/${this.component}`)
        }
    }

    get compRoute () {
        return {
            name: this.name,
            path: this.path,
            meta: this.meta,
            component: () => import(/* webpackMode: "lazy" */ `@/${this.component}`)
        }
    }

}

export {
    Option,
    ChoiceField,
    EditField,
    Field,
    FieldType,
    MultiField,
    Route,
    DraggableField,
    LineField
}