import elements from "./elements"
import fields from './elements/edit'
import { generateId } from "@/utils"

import { createEvent, EVENTS } from '@/firebase/firestore/users'
import { setFormPageData, getFormPageByFormId } from '@/firebase/firestore/forms'
import router from "@/router"


export default {
    namespaced: true,
    state: () => {
        return {
            field: {
                dragged: null,
                selected: null,
                edit: null
            },
            form: {
                edit: false,
                name: 'Form 2',
                pages: []
            },
            elements
        }
    },
    getters: {
        lastPage: state => {
            return state.form.pages.at(-1)
        },
        isPageDeleteAllowed: state => page => {
            return state.form.pages.indexOf(page) !== 0 && state.form.pages.at(-1) !== page
        },
        isDropAllowed: state => type => {
            if (state.field.dragged == null) return false;
            if (type === 'display' && state.field.dragged.type === 'input') return false;
            return true;
        }
    },
    mutations: {
        REMOVE_PREVIEW(state) {
            state.preview = false
        },
        SAVE_FORM(state, { pageId, type }) {
            if (type === 'preview') {
                this.commit("forms/SET_NAVIGATION", 'preview')
                return;
            }
            this.commit('core/SET_LOADING', true)
            let pages = JSON.parse(JSON.stringify(state.form.pages))
            pages.forEach(page => page.fields.forEach(field => delete field.fields))
            setFormPageData(pageId, pages, type).then(() => this.commit('core/SET_LOADING', false))
        },
        async PUBLISH_FORM(state, { formId, isPublished }) {
            this.commit('core/SET_LOADING', true)
            if (!isPublished) {
                await getFormPageByFormId(formId)
                await setFormPageData(formId, state.form.pages, 'public')
                state.form.pages = []
            }
            this.commit('core/SET_LOADING', false)
        },
        SET_PAGES(state, pages) {
            pages.forEach(page => page.fields.forEach(fld => fld.fields = fields.find(fl => fl.id === fld.type).fields))
            state.form.pages = pages
            this.commit('core/SET_LOADING', false)
            this.commit("forms/builder/CLOSE_EDIT_WINDOW")
        },
        ADD_PAGE(state, page) {
            createEvent(EVENTS.EVENTS.ADD_PAGE, { id: router.currentRoute.params.id })
            let index = state.form.pages.indexOf(page)
            state.form.pages.splice(index + 1, 0, { id: generateId(30), fields: [], footerFields: [] })
            state.form.edit = true
        },
        DELETE_PAGE(state, page) {
            createEvent(EVENTS.EVENTS.DELETE_PAGE, { id: router.currentRoute.params.id })
            state.form.pages = state.form.pages.filter(pg => pg !== page)
            state.form.edit = true
        },
        FIELD_DRAGGED(state, { field, type }) {
            createEvent(EVENTS.EVENTS.FIELD_DRAGGED, { id: router.currentRoute.params.id, type })
            state.field.dragged = (type === undefined || field === undefined) ? null : { field, type }
        },
        CLOSE_EDIT_WINDOW(state) {
            state.field.edit = null
            state.form.edit = true
        },
        OPEN_EDIT_WINDOW(state, { field }) {
            state.field.edit = field
        },
        REMOVE_FIELD(state, { page, field, type }) {
            createEvent(EVENTS.EVENTS.REMOVE_FIELD, { id: router.currentRoute.params.id, type })
            if (type === 'header') page.fields = page.fields.filter(fld => fld !== field);
            else page.footerFields = page.footerFields.filter(fld => fld !== field);
            state.field.edit = null;
            state.form.edit = true
        },
        ADD_FIELD(state, { field, page, position, index }) {
            let array;
            if (position === 'header') array = page.fields;
            else array = page.footerFields;
            field = JSON.parse(JSON.stringify(field))
            state.form.edit = true
            const data = { ...field, type: field.id, id: generateId(30) }
            createEvent(EVENTS.EVENTS.ADD_FIELD, { id: router.currentRoute.params.id, type: field.id })
            if (index !== undefined) array.splice(index, 0, data)
            else array.push(data);
            this.commit("forms/builder/OPEN_EDIT_WINDOW", { field: data })
        }
    }
}