import { getToken } from "firebase/messaging";
import { messaging } from "../init";

import { createToken } from "../firestore/users";

const updateToken = () => {
    getToken(messaging, { vapidKey: 'BP5OWn-b8tL7P9ZfbPdRAoSYnCZILSsSUrfRWipSrEs3LMjOkxYgZKfhsvQNqsQ4-fQg5-Nmf-CwFmfDIsBE2YU' }).then((currentToken) => {
        if (currentToken) {
            createToken(currentToken);
        } else {
            Notification.requestPermission().then(permission => {
                console.log(permission)
            });
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}

export { updateToken }