import { onAuthStateChanged } from "firebase/auth";
import { auth } from '@/firebase/init'
import { setUser } from "../analysis";
import { updateToken } from "../messaging";

import store from '@/store'
import router from '@/router'

store.commit('core/SET_LOADING', true)
onAuthStateChanged(auth, user => {
    store.commit('core/SET_LOADING', false)
    store.commit('core/SET_AUTH_LOADED')
    if (user) {
        setUser(user.uid);
        updateToken();
        store.commit('user/SET_USER', user.uid);
        store.dispatch('forms/GET_FORMS');
        return;
    }
    setUser(null);
    store.commit('user/SET_USER', null);
    if (router.currentRoute.path.startsWith('/account'))
    router.push({ name: 'home' }).catch(() => {})
});