export default {
    state: () => {
        return {
            sections: [
                {
                    id: 1,
                    type: "title",
                    columns: [
                        {
                            id: 1,
                            image: {
                                url: "",
                                label: "Test"
                            },
                            label: "Manas Nikam"
                        }
                    ]
                },
                {
                    id: 2,
                    type: "description",
                    columns: [
                        {
                            id: 1,
                            type: "image",
                            image: {
                                url: "",
                                label: "Test"
                            }
                        },
                        {
                            id: 2,
                            type: "list",
                            rows: [
                                { id: 1, title: "Auto Complete", subTitle: "Enables the browser to pre-fill the form with data previously entered", path: "" },
                                { id: 2, title: "Calculator", subTitle: "Use data entered in previous fields to fill or display in other fields", path: "" },
                                { id: 3, title: "Title 3", subTitle: "Sub Title 3", path: "" }
                            ]
                        },
                        {
                            id: 3,
                            type: "list",
                            rows: [
                                { id: 1, title: "Title 1", subTitle: "Sub Title 1", path: "" },
                                { id: 2, title: "Title 2", subTitle: "Sub Title 2", path: "" },
                                { id: 3, title: "Title 3", subTitle: "Sub Title 3", path: "" }
                            ]
                        }
                    ]
                }
            ]
        }
    }
}