import {
    imageField,
    textField,
    titleField,
    videoField
} from "./display";
import {
    fileUploadField,
    fingerprintField,
    imageUploadField,
    qrOrBarCodeScanField
} from "./custom";
import {
    addressField,
    emailField,
    fullNameField,
    mobileNoField,
    termsAndConditionsField
} from "./preconfigured";
import {
    dropdownField,
    dateAndTimeField,
    dateField,
    multiChoiceField,
    signatureField,
    singleChoiceField,
    sliderField,
    switchField,
    textInputField,
    textInputMultiLineField,
    timeField
} from "./input";

export default [
    textField,
    titleField,
    videoField,
    imageField,

    fileUploadField,
    fingerprintField,
    imageUploadField,
    qrOrBarCodeScanField,

    emailField,
    addressField,
    fullNameField,
    mobileNoField,
    termsAndConditionsField,

    dateField,
    timeField,
    switchField,
    sliderField,
    dropdownField,
    signatureField,
    textInputField,
    multiChoiceField,
    dateAndTimeField,
    singleChoiceField,
    textInputMultiLineField
]