export default {
    PAGES: {
        COMMON: {
            FEATURES: {
                FIREBASE: 'common_features_page',
                SITE: 'COMMON_FEATURES_PAGE'
            },
            HOME: {
                FIREBASE: 'common_home_page',
                SITE: 'COMMON_HOME_PAGE'
            },
            INTEGRATIONS: {
                FIREBASE: 'common_integrations_page',
                SITE: 'COMMON_INTEGRATIONS_PAGE'
            },
            PRICINGS: {
                FIREBASE: 'common_pricings_page',
                SITE: 'COMMON_PRICINGS_PAGE'
            },
            SUPPORT: {
                FIREBASE: 'common_support_page',
                SITE: 'COMMON_SUPPORT_PAGE'
            },
            TEMPLATES: {
                FIREBASE: 'common_templates_page',
                SITE: 'COMMON_TEMPLATES_PAGE'
            }
        },
        ACCOUNT: {
            FORMS: {
                FIREBASE: 'account_forms_page',
                SITE: 'ACCOUNT_FORMS_PAGE',
                BUILDER: {
                    FIREBASE: 'account_forms_builder_page',
                    SITE: 'ACCOUNT_FORMS_BUILDER_PAGE'
                },
                ENTRIES: {
                    FIREBASE: 'account_forms_entries_page',
                    SITE: 'ACCOUNT_FORMS_ENTRIES_PAGE'
                },
                PREVIEW: {
                    FIREBASE: 'account_forms_preview_page',
                    SITE: 'ACCOUNT_FORMS_PREVIEW_PAGE'
                },
                SHARE: {
                    FIREBASE: 'account_forms_share_page',
                    SITE: 'ACCOUNT_FORMS_SHARE_PAGE'
                }
            },
            PROFILE: {
                FIREBASE: 'account_profile_page',
                SITE: 'ACCOUNT_PROFILE_PAGE'
            },
            TEMPLATES: {
                FIREBASE: 'account_templates_page',
                SITE: 'ACCOUNT_TEMPLATES_PAGE'
            },
            USERS: {
                FIREBASE: 'account_users_page',
                SITE: 'ACCOUNT_USERS_PAGE'
            }
        }
    },
    EVENTS: {
        CREATE_FORM: {
            FIREBASE: 'create_form',
            SITE: 'CREATE_FORM'
        },
        CREATE_FORM_PAGES: {
            FIREBASE: 'create_form_pages',
            SITE: 'CREATE_FORM_PAGES'
        },
        UPDATE_FORM_PAGES: {
            FIREBASE: 'update_form_pages',
            SITE: 'UPDATE_FORM_PAGES'
        },
        FORM_COUNT: {
            FIREBASE: 'form_count',
            SITE: 'FORM_COUNT'
        },
        GET_FORM: {
            FIREBASE: 'get_form',
            SITE: 'GET_FORM'
        },
        GET_FORM_PAGES: {
            FIREBASE: 'get_form_pages',
            SITE: 'GET_FORM_PAGES'
        },
        FORM_STATUS_CHANGE: {
            FIREBASE: 'form_status_change',
            SITE: 'FORM_STATUS_CHANGE'
        },
        DELETE_FORM: {
            FIREBASE: 'delete_form',
            SITE: 'DELETE_FORM'
        },
        ADD_PAGE: {
            FIREBASE: 'add_page',
            SITE: 'ADD_PAGE'
        },
        DELETE_PAGE: {
            FIREBASE: 'delete_page',
            SITE: 'DELETE_PAGE'
        },
        FIELD_DRAGGED: {
            SITE: 'FIELD_DRAGGED'
        },
        REMOVE_FIELD: {
            SITE: 'REMOVE_FIELD'
        },
        ADD_FIELD: {
            SITE: 'ADD_FIELD'
        }
    }
}