import { ChoiceField, MultiField, Option } from "@/classes";
import { description, label, maximumDate, maximumTime, minimumDate, minimumTime, multiId, multiLabel, placeholder, value } from "../common/fields";


const dropdownField = {
    id: "dropdown",
    name: "Dropdown",
    data: {
        static: {
            label: "Dropdown",
            options: [
                { id: "dropdown-1", label: "Dropdown 1" },
                { id: "dropdown-2", label: "Dropdown 2" }
            ],
            description: ""
        }
    },
    fields: [
        label(),
        description(),
        new MultiField("multi", "Add Dropdowns", "options", [ multiId('Dropdown'), multiLabel('Dropdown') ]).data
    ]
}

const dateAndTimeField = {
    id: "dateAndTime",
    name: "Date And Time",
    data: {
        static: {
            label: "Date Time",
            placeholder: "Select Date And Time",
            hourFormat: '24',
            seconds: false,
            minimumDate: null,
            maximumDate: null,
            incrementMinutes: null,
            incrementHours: null
        }
    },
    fields: [
        label(),
        placeholder(),
        new ChoiceField("singleChoice", "Hour Format", "12", "hourFormat", [
            new Option("12", "12 Hours").data,
            new Option("24", "24 Hours").data
        ]).data,
        minimumDate(),
        maximumDate()
        // {
        //     type: "number",
        //     label: "Increment Minutes",
        //     placeholder: "Enter Increment Minutes",
        //     elm: "incrementMinutes",
        //     max: 60
        // },
        // {
        //     type: "number",
        //     label: "Increment Hours",
        //     placeholder: "Enter Increment Hours",
        //     elm: "incrementHours",
        //     max: 12
        // },
    ]
}

const dateField = {
    id: "date",
    name: "Date",
    data: {
        static: {
            label: "Select Date",
            placeholder: "Select a Date",
            minimumDate: null,
            maximumDate: null
        }
    },
    fields: [
        label(),
        placeholder(),
        description(),
        minimumDate(),
        maximumDate()
    ]
}

const multiChoiceField = {
    id: "multiChoice",
    name: "Multi Choice",
    data: {
        static: {
            label: "Multi Choice",
            options: [
                { id: "choice-1", label: "Choice 1" },
                { id: "choice-2", label: "Choice 2" }
            ],
            required: false,
            description: ""
        }
    },
    fields: [
        label(),
        description(),
        new MultiField("multi", "Add Choices", "options", [ multiId("Choice"), multiLabel("Choice")]).data
    ]
}

const ratingsField = {
    id: "ratings",
    name: "Ratings",
    data: {
        static: {
            label: "Ratings",
            max: 5,
            size: null
        }
    },
    fields: [
        label(),
        new ChoiceField("singleChoice", "Maximum Ratings", 5, "max", [
            new Option(1, "1").data,
            new Option(2, "2").data,
            new Option(3, "3").data,
            new Option(4, "4").data,
            new Option(5, "5").data,
            new Option(6, "6").data,
            new Option(7, "7").data,
            new Option(8, "8").data,
            new Option(9, "9").data,
            new Option(10, "10").data
        ]).data,
        new ChoiceField("singleChoice", "Size", "normal", "size", [
            new Option("mini", "Mini").data,
            new Option("tiny", "Tiny").data,
            new Option("small", "Small").data,
            new Option("normal", "Normal").data,
            new Option("large", "Large").data,
            new Option("huge", "Huge").data,
            new Option("massive", "Massive").data
        ]).data
    ]
}

const singleChoiceField = {
    id: "singleChoice",
    name: "Single Choice",
    data: {
        static: {
            label: "Single Choice",
            placeholder: "Select a Choice",
            options: [
                { id: "choice-1", label: "Choice 1" },
                { id: "choice-2", label: "Choice 2" }
            ],
            required: false,
            description: "",
            value: "",
        }
    },
    fields: [
        label(),
        placeholder(),
        description(),
        value(),
        new MultiField("multi", "Add Choice", "options", [ multiId("Choice"), multiLabel("Choice") ]).data
    ]
}

const sliderField = {
    id: "slider",
    name: "Slider",
    data: {
        static: {
            label: "Slider",
            size: null,
            min: 0,
            max: 100
        }
    },
    fields: [
        label(),
        {
            type: "textInput",
            label: "Minimum",
            placeholder: "Enter Minimum Value",
            value: 0,
            elm: "min"
        },
        {
            type: "textInput",
            label: "Maximum",
            placeholder: "Enter Maximum Value",
            value: 100,
            elm: "max"
        },
        new ChoiceField("singleChoice", "Slider Size", "", "size", [
            new Option("is-small", "Small").data,
            new Option("", "Normal").data,
            new Option("is-medium", "Medium").data,
            new Option("is-large", "Large").data,
        ]).data
    ]
}

const switchField = {
    id: "switch",
    name: "Switch",
    data: {
        static: {
            label: "Switch",
            description: ""
        }
    },
    fields: [
        label(),
        description()
    ]
}

const textInputField = {
    id: 'textInput',
    name: "Text Input",
    data: {
        static: {
            label: "Text Input",
            placeholder: "Enter text",
            required: false,
            description: "",
            value: "",
        },
        validations: ["max", "min", "regex", "case"]
    },
    fields: [
        label(),
        placeholder(),
        description(),
        value()
    ]
}

const textInputMultiLineField = {
    id: 'textInputMultiLine',
    name: "Text Input (Multi Line)",
    data: {
        static: {
            label: "Text Input",
            placeholder: "Enter text",
            required: false,
            description: "",
            value: "",
            counter: false
        }
    },
    fields: [
        label(),
        placeholder(),
        description(),
        value()
    ]
}

const timeField = {
    id: "time",
    name: "Time",
    data: {
        static: {
            label: "Time",
            placeholder: "Select a Time",
            hourFormat: '24',
            seconds: false,
            minimumTime: null,
            maximumTime: null
        }
    },
    fields: [
        label(),
        placeholder(),
        description(),
        new ChoiceField("singleChoice", "Time Format", "12", "hourFormat", [
            new Option("12", "12 Hours").data,
            new Option("24", "24 Hours").data
        ]).data,
        minimumTime(),
        maximumTime()
    ]
}

const signatureField = {
    id: "signature",
    name: "Signature"
}

const multipleChoiceTableField = {
    id: "multipleChoiceTable",
    name: "Multiple Choice Table"
}

export {
    dropdownField,
    dateAndTimeField,
    dateField,
    multiChoiceField,
    ratingsField,
    singleChoiceField,
    sliderField,
    switchField,
    textInputField,
    textInputMultiLineField,
    timeField,
    signatureField,
    multipleChoiceTableField
}