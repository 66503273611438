<template>
    <div class="navbar-main">
        <b-navbar class="container is-max-widescreen navbar-main" style="height: 5em;">
            <template #brand>
                <b-navbar-item @click="route('/')">
                    <img src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"
                        alt="Formeer">
                </b-navbar-item>
            </template>
            <template #end>
                <b-navbar-item v-if="$route.path !== '/' && !$route.path.startsWith('/account')" @click="route('/')">Home</b-navbar-item>
                <b-navbar-item v-if="$store.state.user.id !== null && !$route.path.startsWith('/account')" @click="route('/account/form')">Forms</b-navbar-item>
                <b-navbar-item v-for="nav in navs" :active="nav.path === $route.path" @click="route(nav.path)" :key="nav.id">{{nav.label}}</b-navbar-item>
                <b-navbar-item tag="div">
                    <div class="buttons" v-if="!$route.path.startsWith('/account') && $store.state.user.id === null">
                        <b-button type="is-primary" outlined @click="openForm('sign-up')">Sign up</b-button>
                        <b-button type="is-primary" outlined @click="openForm('login')">Log In</b-button>
                    </div>
                    <div class="buttons" v-else>
                        <b-button type="is-primary" outlined @click="logout()">Log Out</b-button>
                    </div>
                </b-navbar-item>
            </template>
        </b-navbar>
    </div>
</template>

<script>
import LoginForm from "@/components/modals/login"
import SignUpForm from "@/components/modals/signup"
import { logoutUser } from '@/firebase/auth'


export default {
    name: 'Root',
    component: {
        LoginForm,
        SignUpForm
    },
    data() {
        return {
            navigations: [
                { id: 1, path: "/features", label: "Features" },
                { id: 2, path: "/templates", label: "Templates" },
                { id: 3, path: "/pricings", label: "Pricings" },
                { id: 4, path: "/integrations", label: "Integrations" },
                { id: 5, path: "/support", label: "Support" }
            ],
            dashboardNavigations: [
                { id: 2, path: "/account/form", label: "Forms" },
                { id: 3, path: "/account/templates", label: "Templates" },
                { id: 4, path: "/account/profile", label: "Profile" },
                { id: 5, path: "/account/users", label: "Users" }
            ]
        }
    },
    computed: {
        navs () {
            return this.$route.path.startsWith("/account") ? this.dashboardNavigations : this.navigations
        }
    },
    methods: {
        logout() {
            logoutUser()
        },
        openForm(id) {
            this.$buefy.modal.open({
                parent: this,
                component: id === "sign-up" ? SignUpForm : LoginForm,
                hasModalCard: true,
                trapFocus: true,
                canCancel: []
            })
        },
        route (path) {
            this.$router.push(path).catch(() => {})
        }
    }
}
</script>