<template>
    <footer class="footer">
        <div class="content has-text-centered">
            <p>
                <strong>One Form</strong> by <a href="https://www.linkedin.com/in/manas-nikam/" target="_blank">Manas NIKAM</a>.
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
    // mounted() {
    //     this.online = navigator.onLine
    //     this.type = navigator.connection.type
    //     this.speed = navigator.connection.downlink
    //     this.speedType = navigator.connection.effectiveType
    //     navigator.connection.onchange = (e) => {
    //         this.type = e.target.type
    //         this.online = navigator.onLine
    //         this.speed = e.target.downlink
    //         this.speedType = e.target.effectiveType
    //     }
    // }
}
</script>

<style scoped>
.footer {
    padding: 1rem 1.5rem 3rem;
}
</style>