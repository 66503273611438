<template>
    <form action="">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Sign Up</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="columns is-multiline">
                    <div class="column is-6">
                        <b-field label="First Name" label-position="inside">
                            <b-input v-model="form.name.first" placeholder="Enter your First Name" required/>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="Last Name" label-position="inside">
                            <b-input v-model="form.name.last" placeholder="Enter your Last Name" required/>
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field label="Email" label-position="inside">
                            <b-input type="email" v-model="form.email" placeholder="Enter your Email ID" required />
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field label="Password" label-position="inside">
                            <b-input type="password" v-model="form.password" password-reveal placeholder="Your password" required />
                        </b-field>
                    </div>
                    <div class="column is-12" v-if="form.password.length > 0">
                        <b-field label="Confirm Password" label-position="inside">
                            <b-input type="password" v-model="form.confirmPassword" password-reveal placeholder="Your password" required />
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-checkbox v-model="form.checked">You agree to our <a href="/policies/terms-and-conditons" target="_blank">Terms of Service</a> and <a href="/policies/privacy-policy" target="_blank">Privacy Policy</a></b-checkbox>
                    </div>
                    <div class="column is-12">
                        <b-notification style="text-align: center;" :closable="false" :active="boolean.notification" type="is-danger" role="alert">{{ message.error }}</b-notification>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="columns" style="width: 100%;">
                    <div class="column is-6 is-offset-6">
                        <b-button label="Sign Up" type="is-primary" expanded @click="signUp()" :disabled="form.name.first.length === 0 || form.name.last.length === 0 || form.email.length < 4 || form.password.length < 3 || form.password !== form.confirmPassword || !form.checked"/>
                    </div>
                </div>
            </footer>
        </div>
    </form>
</template>

<script>
import LoginForm from '@/components/modals/login'
import { createUserAccount } from '@/firebase/auth'

export default {
    name: "SignUpForm",
    data() {
        return {
            message: {
                error: "Account already exist."
            },
            boolean: {
                notification: false
            },
            form: {
                name: {
                    first: "",
                    last: ""
                },
                checked: false,
                email: "",
                password: "",
                confirmPassword: ""
            }
        }
    },
    methods: {
        async signUp () {
            this.$store.commit('core/SET_LOADING', true)
            try {
                await createUserAccount({ first: this.form.name.first, last: this.form.name.last }, this.form.email, this.form.password)
                this.$emit('close')
                this.$router.push({ name: "dashboard" })
            } catch(e) {
                this.boolean.notification = true
                setTimeout(() => {
                    this.$buefy.modal.open({
                        parent: this,
                        component: LoginForm,
                        hasModalCard: true,
                        trapFocus: true,
                        canCancel: []
                    });
                    this.$emit('close')
                }, 3000)
                this.form.email = ""
                this.form.password = ""
                this.form.confirmPassword = ""
            }
            this.$store.commit('core/SET_LOADING', false)
        }
    }
}
</script>