import { createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { createUser } from '@/firebase/firestore/users'
import { auth } from '@/firebase/init'

import store from "@/store";

const createUserAccount = async (name, email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    createUser(userCredential.user.uid, name, email)
    updateProfile(userCredential.user, { displayName: `${name.first} ${name.last}` })
}

const logoutUser = () => {
    store.commit('forms/RESET')
    signOut(auth)
}

const loginUser = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
}

export { createUserAccount, logoutUser, loginUser }