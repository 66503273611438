export default {
    namespaced: true,
    state: () => {
        return {
            id: null,
            token: null
        }
    },
    mutations: {
        SET_USER(state, userId) {
            state.id = userId
        },
        SET_TOKEN(state, accessToken) {
            state.token = accessToken;
        }
    }
}